import { createStore } from "vuex";

//Agarra el index.js si se deja solamente asi
import mainAppModule from "@/modules/mainapp/store";
import dashAppModule from "@/modules/dashboard/store";
import productStore from "@/store/productStore";
import lineStore from "@/store//lineStore";
import categorieStore from "@/store/categorieStore";
import imageStore from "@/store/imageStore";
import userStore from "@/store/userStore";
import colorStore from "@/store/colorStore";
import excelStore from "@/store/excelStore";
import rolStore from "@/store/rolStore";
import tagsStore from "@/store/tagsStore";
import quoteStore from "@/store/quoteStore";
import rolluxStore from "@/store/rolluxStore";

export default createStore({
  modules: {
    lineStore,
    categorieStore,
    productStore,
    mainAppModule,
    dashAppModule,
    imageStore,
    userStore,
    colorStore,
    excelStore,
    rolStore,
    tagsStore,
    quoteStore,
    rolluxStore
  },
});
