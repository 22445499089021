import axios from 'axios';

const quoteStore = {
    state: {
        quote: 45,
        quotes: 27,
        cart: 0,
    },
    actions: {
        async getTotalQuotes({ commit }) {
            try {
                const response = await axios.get('/api/user/total-quotes');
                commit('setQuotes', response.data);
            } catch (error) {

            }
        },
        async getDistribuitorsQuotes({ commit }) {
            try {
                const response = await axios.get('/api/user/total-distribuitors-quotes');
                commit('setDisQuotes', response.data);
            } catch (error) {

            }
        },
        async getTotalCart({ commit }) {
            try {
                const response = await axios.get('/api/user/total-cart');
                commit('setCart', response.data);
            } catch (error) {

            }
        },
    },
    mutations: {

        setQuotes(state, quote) {
            state.quote = quote;
        },
        setDisQuotes(state, quotes) {
            state.quotes = quotes;
        },
        setCart(state, cart) {
            state.cart = cart;
        },

    }

}
export default quoteStore;