import axios from "axios"

const rolStore = {
    state:{
        roles : []
    },
    mutations:{
        setRoles(state, roles){
            state.roles = roles;
        }
    },
    actions:{
        async getRoles({commit}){
            try {
                const response = await axios.get('/api/roles');
                commit('setRoles', response.data);
            } catch (error) {
                
            }
        }
    }
}

export default rolStore;