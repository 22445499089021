import axios from "axios";
const lineStore = {
  state: {
    lines: [],
    sublines: [],
    errors: [],
  },
  mutations: {
    setLines(state, lines) {
      state.lines = lines;
      state.errors = [];
    },
    setSublines(state, sublines) {
      state.sublines = sublines;
      state.errors = [];
    },
    saveLines(state, lines) {
      state.lines.push(lines);
      state.errors = [];
    },
    deleteLines(state, lines) {
      let p = state.lines.find((p) => p.id === lines.id);
      state.lines.splice(state.lines.indexOf(p), 1);
    },
    editLines(state, lines) {
      let p = state.lines.find((p) => p.id === lines.id);
      Object.assign(p, lines);
    },
    clearErrors(state) {
      state.errors = [];
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
  },
  actions: {
    async getLines({ commit }) {
      try {
        const response = await axios.get("/api/lines");
        commit("setLines", response.data);
      } catch (error) {}
    },
    async saveLines({ commit }, lines) {
      let p = new FormData();
      for (const prop in lines) {
        p.append(prop, lines[prop]);
      }
      try {
        const response = await axios.post("/api/lines", p);
        commit("saveLines", response.data);
      } catch (error) {
        commit("setErrors", error.response.data);
      }
    },
    async deleteLines({ commit }, id) {
      try {
        const response = await axios.delete("/api/lines/" + id);
        commit("deleteLines", response.data);
      } catch (error) {}
    },

    async editLines({ commit }, lines) {
      try {
        const response = await axios.put("/api/lines/" + lines.id, lines);
        commit("editLines", response.data);
      } catch (error) {
        commit("setErrors", error.response.data);
      }
    },
    async getlinesByCategory({ commit }, slug) {
      try {
        const response = await axios.get("/api/lines/category/" + slug);
        commit("setLines", response.data);
      } catch (error) {}
    },
    async getlinesBySubCategory({ commit }, slug) {
      try {
        const response = await axios.get("/api/lines/subcategory/" + slug);
        commit("setLines", response.data);
      } catch (error) {}
    },
    async getSublinesBylines({ commit }, id) {
      try {
        const response = await axios.get("/api/lines/sublines/" + id);
        commit("setSublines", response.data);
      } catch (error) {}
    },
    async clearLineErrors({ commit }) {
      try {
        commit("clearErrors");
      } catch (error) {}
    },
  },
};
export default lineStore;
