import axios from "axios";
const rolluxStore = {
  state: {
    blinds: [],
    types: [],
    weaves: [],
    blind: { colors: [], weave: {} },
  },
  actions: {
    async getTypes({ commit }) {
      try {
        const response = await axios.get(
          "http://server.rollux.mx/api/on-types-product/persianas"
        );
        commit("setTypes", response.data);
        return true;
      } catch (error) {}
    },
    async getWeaves({ commit }, type) {
      try {
        const response = await axios.get(
          "http://server.rollux.mx/api/weaves-type/" + type
        );
        commit("setWeave", response.data);
        return true;
      } catch (error) {
        return false;
      }
    },
    async getBlinds({ commit }, type) {
      try {
        const response = await axios.get(
          "http://server.rollux.mx/api/on-variants-type-weave/" + type
        );
        commit("setBlinds", response.data);
        return true;
      } catch (error) {
        return false;
      }
    },
    async getBlindsWeave({ commit }, types) {
      try {
        const response = await axios.get(
          "http://server.rollux.mx/api/on-variants-type-weave/" +
            types.type +
            "/" +
            types.weave
        );
        commit("setBlinds", response.data);
        return true;
      } catch (error) {
        return false;
      }
    },
    async getBlind({ commit }, slug) {
      try {
        const response = await axios.get(
          "http://server.rollux.mx/api/variants/" + slug
        );
        commit("setBlind", response.data);
        return true;
      } catch (error) {
        return false;
      }
    },
  },
  mutations: {
    setTypes(state, types) {
      state.types = types;
    },
    setWeave(state, weaves) {
      state.weaves = weaves;
    },
    setBlinds(state, blinds) {
      state.blinds = blinds;
    },
    setBlind(state, blind) {
      state.blind = blind;
    },
  },
};

export default rolluxStore;
