import axios from "axios";
const tagsStore = {
  state: {
    tags: [],
    errors: [],
  },
  actions: {
    async getTags({ commit }) {
      try {
        const response = await axios.get("/api/tags");
        commit("setTags", response.data);
        return true;
      } catch (error) {
        //
      }
    },
    async postNewTags({ commit }, tag) {
      try {
        const response = await axios.post("/api/tags", tag);
        commit("saveNewTag", response.data);
        return true;
      } catch (error) {
        commit("setErrors", error.response.data);
        return false;
      }
    },
    async deleteTagProducts({ commit }, tag) {
      try {
        const response = await axios.post("/api/deleteTagByProduct", tag);
        return true;
      } catch (error) {
        commit("setErrors", error.response.data);
        return false;
      }
    },
    async clearTagsErrors({ commit }) {
      commit("clearErrors");
    },
  },
  mutations: {
    setTags(state, tags) {
      state.tags = tags;
      state.errors = [];
    },
    saveNewTag(state, tag) {
      state.tags.push(tag);
      state.errors = [];
    },
    clearErrors(state) {
      state.errors = [];
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
  },
};
export default tagsStore;
