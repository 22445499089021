import axios from "axios";
const categorieStore = {
  state: {
    categories: [],
    categorie: [],
    errors: [],
    listCategory: [],
  },
  mutations: {
    setCategories(state, categories) {
      state.categories = categories;
    },
    setListCategories(state, categories) {
      state.listCategory = categories;
    },
    saveCategories(state, categories) {
      console.log(state.categories);
      console.log(categories);
      state.categories.push(categories);
    },
    deleteCategories(state, categories) {
      let p = state.categories.find((p) => p.id === categories.id);
      state.categories.splice(state.categories.indexOf(p), 1);
    },
    editCategories(state, categories) {
      let p = state.categories.find((p) => p.id === categories.id);
      Object.assign(p, categories);
    },
    clearErrors(state) {
      state.errors = [];
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
  },
  actions: {
    async getCategories({ commit }) {
      try {
        const response = await axios.get("/api/categories");
        commit("setCategories", response.data);
      } catch (error) {}
    },
    async saveCategories({ commit }, categories) {
      let p = new FormData();
      for (const prop in categories) {
        p.append(prop, categories[prop]);
      }
      try {
        const response = await axios.post("/api/categories", p);
        commit("saveCategories", response.data);
      } catch (error) {
        console.log(error);
        commit("setErrors", error.response.data);
      }
    },
    async deleteCategories({ commit }, id) {
      try {
        const response = await axios.delete("/api/categories/" + id);
        commit("deleteCategories", response.data);
      } catch (error) {}
    },
    async editCategories({ commit }, categories) {
      try {
        const response = await axios.put(
          "/api/categories/" + categories.id,
          categories
        );
        commit("editCategories", response.data);
      } catch (error) {
        commit("setErrors", error.response.data);
      }
    },
    async getSubCategoriesV({ commit }, slug) {
      try {
        const response = await axios.get("/api/subcategorys/" + slug);
        commit("setCategories", response.data);
      } catch (error) {}
    },
    async clearCategoriaErrors({ commit }) {
      try {
        commit("clearErrors");
      } catch (error) {}
    },
    async getNameCate({ commit }, id) {
      try {
        const response = await axios.get("/api/category-name/" + id);
        commit("setListCategories", response.data);
        return true;
      } catch (error) {
        return false;
      }
    },
  },
};
export default categorieStore;
