import axios from "axios"
const excelStore = {
    state: {
        file:[],
        errors: []
    },
    mutations: {
        setExcel(state, file) {
            state.file = file;
        },
        clearErrors(state){
            state.errors = [];
        },
        setErrors(state, errors) { state.errors = errors }
    },
    actions: {
        async postImportProducts({ commit },file) {
            let p = new FormData();
            p.append('file',file);
            try {
               await axios.post('api/productsimport',p);
               commit('setErrors',[])
               return true;
            } catch (error) {
                commit('setErrors', error.response.data.message);
                return false;
            }
        },
        async exportProducts() {
            try {
                await axios.get('api/products-export');
                
            } catch (error) {
                commit('setErrors', error.response.data);
            }
        },
        async clearExcelErrors({ commit }) {
            try {
                commit('clearErrors');
            } catch (error) {

            }
        },

    }
}
export default excelStore;