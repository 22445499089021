// export const myAction = async ({commit}) => {

//Son acciones asincronas que llaman mutaciones
// Reciben el context y se puede desestructurar, por eso solo se saca CustomElementRegistry
// context.commit('commitAlgo')

// }


export const defaultAction = async (/*{commit}*/) => {


}
