import axios from "axios"
const imageStore = {
    state: {
        images: [],
        errors: []
    },
    mutations: {
        setImages(state, images) {
            state.images = images
            state.errors = []
        },
        saveImages(state, images) {
            state.images.push(images);
            state.errors = []
        },
        deleteImages(state, id) {
            let p = state.images.find((p => p.id === id))
            state.images.splice(state.images.indexOf(p), 1)
        },
        editImages(state, images) {
            let p = state.images.find((p => p.id === images.id))
            Object.assign(p, images)
        },
        clearErrors(state){
            state.errors = [];
        },
        setErrors(state, errors) { state.errors = errors }
    },
    actions: {
        async getImagesByProduct({ commit },product_id) {
            try {
                const response = await axios.get('/api/images/prudct/'+product_id);
                commit('setImages', response.data);
            } catch (error) {

            }
        },
        async getImagesByColor({ commit },color_id) {
            try {
                const response = await axios.get('/api/images/color/'+color_id);
                commit('setImages', response.data);
            } catch (error) {

            }
        },
        async saveImages({ commit }, images) {
            let p = new FormData();
            for (const prop in images) {
                p.append(prop, images[prop])
            }
            try {
                const response = await axios
                    .post('/api/images', p)
                commit('saveImages', response.data);

            } catch (error) {
                commit('setErrors', error.response.data);
            }
        },
        async deleteImages({ commit }, id) {
            try {
                const response = await axios
                    .delete('/api/images/' + id)
                commit('deleteImages', id);

            } catch (error) {

            }
        },
        async editImages({ commit }, images) {
            try {
                const response = await axios
                    .put('/api/images/' + images.id, images)
                commit('editImages', response.data);

            } catch (error) {
                commit('setErrors', error.response.data);
            }
        },
        async orderImages({ commit }, images) {
            try {
                const response = await axios
                    .put('/api/images/neworder/' + images.id, images)
                commit('editImages', response.data);

            } catch (error) {
                commit('setErrors', error.response.data);
            }
        },
        async clearImageErrors({ commit }) {
            try {
                commit('clearErrors');
            } catch (error) {

            }
        },
        
        
    }
}
export default imageStore;