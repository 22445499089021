// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify, useTheme } from 'vuetify'

export default createVuetify({
  theme:{
    themes:{
      light:{
        morado:'#2a294c',
        moradofondo:'#100f2f',
        amarillo:'#f1d100',
        madecverde:'#3396a3',
        madecazul:'#4fe9fd'
      }
      
    }
   }
});
