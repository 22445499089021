import axios from "axios"
const colorStore = {
    state: {
        colors: [],
        errors: []
    },
    mutations: {
        setColor(state, colors) {
            state.colors = colors
        },
        
        deleteCategories(state, categories) {
            let p = state.categories.find((p => p.id === categories.id))
            state.categories.splice(state.categories.indexOf(p), 1)
        },
        editColor(state, color) {
            let p = state.colors.find((p => p.id === color.id))
            Object.assign(p, color)

        },
        clearErrors(state){
            state.errors = [];
        },
        setErrors(state, errors) { state.errors = errors }

    },
    actions: {
        async saveColor({ commit }, color) {
            let p = new FormData();
            for (const prop in color) {
                p.append(prop, color[prop])
            }
            try {
                const response = await axios
                    .post('/api/color', p)
                commit('setColor', response.data);

            } catch (error) {
                commit('setErrors', error.response.data);
            }
        },
        async deleteColor({ commit }, id) {
            try {
                const response = await axios.delete('/api/categories/' + id)
                commit('deleteCategories', response.data);

            } catch (error) {

            }
        },
        async editColor({ commit }, color) {
            try {
                const response = await axios.put('/api/color/' + color.id, color);
                commit('editColor', response.data);
                return true;
            } catch (error) {
                if(error.response){
                    console.log(error.response.data);
                    commit('setErrors', error.response.data);
                }
            }
        },
        async editDiscountC({ commit }, color) {
            try {
                const response = await axios.put('/api/color/discount/' + color.id, color);
                commit('editColor', response.data);
                return true;
            } catch (error) {
                if(error.response){
                    commit('setErrors', error.response.data);
                }
            }
        },
        async clearColorErrors({ commit }) {
            try {
                commit('clearErrors');
            } catch (error) {

            }
        },
        
        


    }
}
export default colorStore;