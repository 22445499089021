export default { //Se exporta un objeto como tal

    name: 'dash-layout-app',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/modules/dashboard/layouts/DashLayoutApp.vue'),
    children: [


        {
            path: '/dashboard/stock/lines',
            name: 'lines',
            component: () => import('@/modules/dashboard/views/Lines.vue'),
        }
        ,
        {
            path: '/dashboard/stock/categories',
            name: 'categoriesV',
            component: () => import('@/modules/dashboard/views/CategoriesV.vue'),
        },
        
        {
            path: '/dashboard/users',
            name: 'users',
            component: () => import('@/modules/dashboard/views/users.vue'),
        },
        {
            path: '/dashboard/myaccount',
            name: 'myaccount',
            component: () => import('@/modules/dashboard/views/Myaccount.vue'),
        }
        ,
        {
            path: '/dashboard',
            name: 'inicio',
            component: () => import('@/modules/dashboard/views/Inicio.vue'),
        },
        {
            path: '/dashboard/stock/products',
            name: 'stock',
            component: () => import('@/modules/dashboard/views/stock.vue'),
          },
       




    ]


}