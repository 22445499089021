import axios from "axios";
const productStore = {
  state: {
    products: [],
    productsDis: [],
    product: {},
    categories: [],
    errors: [],
    productRelated: [],
  },
  getters: {
    getCategorie: (state) => (slug) => {
      return state.categories.filter((categorie) => categorie.slug === slug);
    },
  },
  mutations: {
    setProducts(state, products) {
      state.products = products;
    },
    setProductsDis(state, productsDis) {
      state.productsDis = productsDis;
    },
    setProduct(state, product) {
      state.product = product;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    saveProducts(state, products) {
      state.products.push(products);
      state.errors = [];
    },
    deleteProducts(state, products) {
      let p = state.products.find((p) => p.id === products.id);
      state.products.splice(state.products.indexOf(p), 1);
    },
    editProducts(state, products) {
      let p = state.products.find((p) => p.id === products.id);
      Object.assign(p, products);
    },
    clearErrors(state) {
      state.errors = [];
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    setProductsRelated(state, productRelated) {
      state.productRelated = productRelated;
    },
  },
  actions: {
    async getProducts({ commit }) {
      try {
        const response = await axios.get("/api/products");
        commit("setProducts", response.data.data);
      } catch (error) {}
    },
    async getProductsSearch({ commit }, search) {
      try {
        const response = await axios.get("/api/searchproduct/" + search);
        commit("setProducts", response.data.data);
      } catch (error) {}
    },
    async getProductsBySlug({ commit }, slug) {
      try {
        const response = await axios.get("/api/products/" + slug);
        commit("setProduct", response.data.data);
      } catch (error) {}
    },
    async saveProducts({ commit }, products) {
      let p = new FormData();
      for (const prop in products) {
        p.append(prop, products[prop]);
      }
      try {
        const response = await axios.post("/api/products", p);
        commit("saveProducts", response.data);
      } catch (error) {
        commit("setErrors", error.response.data);
      }
    },
    async saveProductsColor({ commit }, products) {
      try {
        const response = await axios.post("/api/products/colors", products);
        commit("saveProducts", response.data);
      } catch (error) {
        commit("setErrors", error.response.data);
      }
    },
    async deleteProducts({ commit }, id) {
      try {
        const response = await axios.delete("/api/products/" + id);
        commit("deleteProducts", response.data);
      } catch (error) {}
    },
    async editProducts({ commit }, products) {
      try {
        const response = await axios.put(
          "/api/products/" + products.id,
          products
        );
        commit("editProducts", response.data);
      } catch (error) {
        commit("setErrors", error.response.data);
      }
    },
    async getProductsV({ commit }, slug) {
      try {
        const response = await axios.get("/api/products/category/" + slug);
        commit("setProducts", response.data.data);
      } catch (error) {}
    },
    async editDiscountP({ commit }, products) {
      try {
        const response = await axios.put(
          "/api/products/discount/" + products.id,
          products
        );
        commit("editProducts", response.data);
        console.log(response.data);
      } catch (error) {
        commit("setErrors", error.response.data);
      }
    },
    async getProductsDis({ commit }) {
      try {
        const response = await axios.get("/api/products/discount/all/" + 1);
        commit("setProductsDis", response.data.data);
      } catch (error) {}
    },
    async clearProductsErrors({ commit }) {
      try {
        commit("clearErrors");
      } catch (error) {}
    },
    async savenewColor({ commit }, color) {
      let p = new FormData();
      for (const prop in color) {
        p.append(prop, color[prop]);
      }
      try {
        const response = await axios.post("/api/products/newcolors", p);
        commit("editProducts", response.data.data);
      } catch (error) {
        commit("setErrors", error.response.data);
      }
    },
    async saveTagsProducts({ commit }, tag) {
      try {
        const response = await axios.post("/api/products/tags", tag);
        commit("setProduct", response.data);
        return true;
      } catch (error) {
        commit("setErrors", error.response.data);
        return false;
      }
    },
    async getProductsRelated({ commit }, slug) {
      try {
        const response = await axios.get("/api/products/related/" + slug);
        commit("setProductsRelated", response.data.data);
        return true;
      } catch (error) {
        return false;
      }
    },
  },
};
export default productStore;
