import { createRouter, createWebHistory } from 'vue-router'

import mainAppRouter from '@/modules/mainapp/router'
import dashAppRouter from '@/modules/dashboard/router'
import store from '@/store'

const routes = [
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
  {
    path: '/',
    ...mainAppRouter //Como es un objeto, se esparce para agregar las propiedades en este objeto
  },
  {
    path: '/dashboard',
    meta: { requiresAuth: true },
    ...dashAppRouter
  }
  ,
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "index" */ '@/modules/mainapp/views/Login.vue'),
  },
  {
    path: '/auth/login',
    name: 'auth',
    component: () => import(/* webpackChunkName: "index" */ '@/modules/mainapp/views/auth.vue'),
  },

  /* {
     path: '/pruebas',
     name: 'pruebas',
     component: () => import('@/modules/mainapp/views/pruebas.vue'),
   },*/




]

const router = createRouter({
  history: createWebHistory(), //Este es el que no tiene el '#/' a inicio de las rutas
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach(function (to, from, next) {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('user_auth') == "true") {
      store.state.userStore.auth = true;
      store.state.userStore.user = JSON.parse(localStorage.getItem('user'));
    }
    if (store.state.userStore.auth == true) {
      next();
    } else {
      next({ name: 'login' });
    }
  } else {
    next();
    if (localStorage.getItem('user_auth') == "true") {
      store.state.userStore.auth = true;
      store.state.userStore.user = JSON.parse(localStorage.getItem('user'));
    }
  }
  
});


export default router
